@import "~@/scss/app.scss";

.code-reader__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mt-2 {
    margin-top: 20px;
}
