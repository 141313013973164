@import "~@/scss/app.scss";

.code-reader__stats {
    margin-top: 40px;
}

.code-reader__stats-line {
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: space-between;
    border-bottom: 1px solid $border;
}

.code-reader__stats-title {
    font-weight: $fwNormal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $black;
}

.code-reader__stats-value {
    font-weight: $fwNormal;
    font-size: 24px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.18px;
    color: $black;
}

.color-violet {
    color: var(--mdc-theme-secondary);
}

.color-cyan {
    color: var(--mdc-theme-tertiary);
}
