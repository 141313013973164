@import "~@/scss/app.scss";

.code-reader__input {
    position: relative;
}

.code-reader__btn {
    width: 100%;
    margin-top: 15px;

    &.--load {
        background-color: #333;
    }
}
