@import "~@/scss/app.scss";

.skipass {
    margin-top: 40px;
    padding: 36px 25px;
    background: $white;
    text-align: center;
    @include border-radius(4px);
    @include shadow(0, 16px, 24px, 0, rgba(0, 0, 0, 0.2));

    &.used,
    &.cancelled {
        background: $error;
    }

    &.applied {
        background: var(--mdc-theme-tertiary);
    }

    &.error {
        border: 1px solid $error;
    }
}

.skipass__title {
    font-weight: $fwNormal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: var(--mdc-theme-tertiary);
    padding-bottom: 20px;
    margin: 0 0 16px 0;
    border-bottom: 1px solid $border;

    .used &,
    .cancelled &,
    .applied & {
        color: $white;
    }

    .error & {
        color: $black;
    }
}

.skipass__date {
    margin-bottom: 16px;

    span {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.6);
        display: block;

        .used &,
        .cancelled &,
        .applied & {
            color: $white;
        }
    }

    strong {
        font-weight: $fwNormal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $black;
        display: block;

        .used &,
        .cancelled &,
        .applied & {
            color: $white;
        }
    }

    h6 {
        display: block;
        margin: 0;
        font-weight: $fwNormal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: var(--mdc-theme-tertiary);

        .used &,
        .cancelled &,
        .applied & {
            color: $white;
        }

        .error & {
            color: $black;
        }
    }
}

.skipass__user,
.skipass__user-info {
    margin-bottom: 16px;

    h3 {
        margin: 0;
        padding: 0;
        font-weight: $fwNormal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $black;

        .used &,
        .cancelled &,
        .applied & {
            color: $white;
        }
    }

    span {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.6);
        display: block;

        .used &,
        .cancelled &,
        .applied & {
            color: $white;
        }
    }
}

.skipass__state {
    margin-bottom: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: #c8fff4;
    padding: 0 15px;
    color: var(--mdc-theme-tertiary);
    @include border-radius(20px);

    .used &,
    .cancelled &,
    .applied & {
        color: $white;
        background: rgba(0, 0, 0, 0.12);
    }

    .error & {
        color: $error;
        background-color: #f8e6e9;
    }
}

.skipass__price {
    margin-bottom: 26px;
    font-weight: $fwNormal;
    font-size: 34px;
    line-height: 36px;
    color: $black;

    .used &,
    .cancelled & {
        color: $white;
    }

    .applied & {
        color: $white;
        margin-bottom: 0;
    }
}

.cta_btn:not(:disabled) {
    background-color: var(--mdc-theme-tertiary);
    // @include shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

    @include less-than(sm) {
        width: calc(50% - 10px);
    }

    .used &,
    .cancelled & {
        pointer-events: none;
        background-color: rgba(33, 33, 33, 0.38);
        @include opacity(0.3);
    }

    .error & {
        background-color: $error;
    }
}

.remove_btn:not(:disabled) {
    margin-left: 20px;
    background: rgba(0, 0, 0, 0.6);

    @include less-than(sm) {
        width: calc(50% - 10px);
    }
}

.skipass__message {
    background: #f7e6e9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 34px;
    background: #f7e6e9;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    max-width: 500px;
    margin: 0 auto 12px;
    @include border-radius(4px);
}
