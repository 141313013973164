@import "~@/scss/app.scss";

.instances {
    display: flex;
    flex-direction: column;

    .instances__instance {
        display: flex;
        background: rgba(33, 33, 33, 0.08);
        padding: 1rem;
        border-radius: 2px;
        margin-bottom: 1rem;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 16px;
        }
    }
}
