@import "~@/scss/app.scss";

.barcode-reader {
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    @include less-than(md) {
        height: calc(var(--vh, 1vh) * 100);
    }
}

.barcode-reader__wrapper {
    width: 100%;
    height: 100%;

    .scanner {
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    canvas {
        display: none;
    }
}

.barcode-reader__mask {
    position: fixed;
    height: 150px;
    width: 95%;
    border: 1000px solid rgba(0, 0, 0, 0.3);
    left: 50%;
    top: 50%;
    @include border-radius(8px);
    @include translate(-50%, -50%, 0);
    @include opacity(1);

    &:before {
        content: '';
        left: -3px;
        right: -3px;
        bottom: -3px;
        top: -3px;
        position: absolute;
        border: 5px solid $white;
        @include border-radius(8px);
        background: rgb(255 0 0 / 10%);
    }

    &.--active {
        &:before {
            background: transparent;
        }
    }
}

.barcode-reader__close {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 12px;
    top: 8px;
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    cursor: pointer;

    @include less-than(md) {
        left: 4px;
        top: 4px;
    }
}
