$black: rgba(0, 0, 0, 0.87);
$white: #fff;
$border: rgba(33, 33, 33, 0.08);
$error: #b00020;

$baseFontSize: 14px;
$baseFont: 'Roboto', Helvetica, Arial, Tahoma, sans-serif;
$fwNormal: normal;
$fwMedium: 500;

$basePath: '~@/assets/images/';
