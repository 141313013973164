@import "~@/scss/app.scss";

.gopass {
    width: 59px;
    height: 14px;
    position: absolute;
    top: 22px;
    right: 16px;
    background: url($basePath + 'gopass.svg') no-repeat center;
}
