//***********************GRID BASE
//********************************
$grid-gutter-width: 30px;
$grid-media-shift: 0.1px;

$grid-breakpoints: (
    xxs: 0,
    xs: 400,
    sm: 768px,
    md: 1024px,
    lg: 1200px,
    xl: 1400px,
);

//***************MEDIAQUERY MIXINS
//********************************
@mixin less-than($breakPoint) {
    @media (max-width: map-get($grid-breakpoints, $breakPoint) - $grid-media-shift) {
        @content;
    }
}

@mixin more-than($breakPoint) {
    @media (min-width: map-get($grid-breakpoints, $breakPoint) + $grid-media-shift) {
        @content;
    }
}
