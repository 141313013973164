// @use "@material/theme" with (
//     $primary: orange,
//     $secondary: #feeae6,
//     $on-primary: #442b2d,
//     $on-secondary: #442b2d,
// );

// @use '~material-components-web/material-components-web.scss';
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
@import '~material-components-web/dist/material-components-web.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/grid';

//components
@import 'components/spinner';

[v-cloak] {
    display: none;
}

:root {
    --mdc-theme-primary: #393562;
    --mdc-theme-secondary: #6200ee;
    --mdc-theme-tertiary: #01a299;
}

html {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    padding: 0;
    color: $black;
    font-size: $baseFontSize;
    font-family: $baseFont;
    overflow-x: hidden;
    text-size-adjust: none !important;
    -moz-text-size-adjust: none !important;
    -webkit-text-size-adjust: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    @include box-sizing(border-box);
}

.container {
    width: 100%;
    padding: 32px 16px 150px;

    &.--big {
        padding: 80px 16px 150px;
    }
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--mdc-theme-secondary);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: rgba(33, 33, 33, 0.08);
}
